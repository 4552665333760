import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import DiscographyListItem from './discography-list-item';

const query = graphql`
  query discographyItemsQuery {
    allDiscographyItemsJson {
      edges {
        node {
          artist
          name
          style
          itunesLink
          image
          artistLink
        }
      }
    }
  }
`;

const DiscographyList = () => {
  const data = useStaticQuery(query);
  const discography = data.allDiscographyItemsJson.edges.map(edge => edge.node);

  return (
    <ul className="discography-list">
      {discography.map(disc => (
        <DiscographyListItem key={disc.itunesLink} disc={disc} />
      ))}
    </ul>
  );
};

export default DiscographyList;
