import React from 'react';
import {
  PageLayout,
  PageLayoutBody,
  PageLayoutHeader,
  PageTitle,
} from '../components/page-layout';
import DiscographyList from '../components/discography/discography-list';
import SEO from '../components/seo';

const Discography = () => (
  <PageLayout className="discography-background">
    <SEO title="Discography" />
    <PageLayoutHeader />
    <PageTitle>Discography</PageTitle>
    <PageLayoutBody className="discography-content">
      <DiscographyList />
    </PageLayoutBody>
  </PageLayout>
);

export default Discography;
