import React from 'react';
import PropTypes from 'prop-types';
import { IoLogoApple } from 'react-icons/io';
import DiscographyBackgroundImage from './discography-background-image';

const DiscographyListItem = ({ disc }) => {
  const itunesClick = () => window.open(disc.itunesLink, '_blank');
  const artistLink = () => window.open(disc.artistLink, '_blank');

  return (
    <li className="discography-list-item-wrapper">
      <DiscographyBackgroundImage
        imagePath={`discography/${disc.image}`}
        className="discography-list-item"
      >
        <div className="discography-list-item-itunes-wrapper">
          <button
            className="discography-list-item-itunes-button"
            onClick={itunesClick}
          >
            <IoLogoApple className="apple-logo" />
            <div className="itunes-banner">Buy on iTunes</div>
          </button>
        </div>

        <div className="discography-list-item-banner" onClick={artistLink}>
          <span className="name">{disc.name}</span>
          <span className="artist">{disc.artist}</span>
          <span className="style">{disc.style}</span>
        </div>
      </DiscographyBackgroundImage>
    </li>
  );
};

DiscographyListItem.propTypes = {
  disc: PropTypes.shape({
    artist: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    style: PropTypes.string.isRequired,
    itunesLink: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    artistLink: PropTypes.string.isRequired,
  }).isRequired,
};

export default DiscographyListItem;
