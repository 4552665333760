import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import GatsbyBackgroundImage from 'gatsby-background-image';

// This is loading all the images in the discography folder.
// The component will take a path and try to match the right image #ghetto
const query = graphql`
  {
    allDiscographyImages: allFile(
      filter: { absolutePath: { regex: "/discography/" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const DiscographyBackgroundImage = ({ children, className, imagePath }) => {
  const data = useStaticQuery(query);
  const allImages = data.allDiscographyImages.edges.map(edge => edge.node);
  const thisImage = allImages.find(img => img.relativePath === imagePath);

  if (!thisImage) return children;

  const imageData = thisImage.childImageSharp.fluid;

  return (
    <GatsbyBackgroundImage
      className={classNames('background-image-wrapper', className)}
      fluid={imageData}
    >
      {children}
    </GatsbyBackgroundImage>
  );
};

DiscographyBackgroundImage.propTypes = {
  children: PropTypes.node.isRequired,
  imagePath: PropTypes.string.isRequired,
  className: PropTypes.string,
};

DiscographyBackgroundImage.defaultProps = {
  className: '',
};

export default DiscographyBackgroundImage;
